import { FiX } from 'react-icons/fi';

function InfoModal({ handleModalClose }) {
  return (
    <div className="modalBackground">
      <div className="modal modalInfo">
        <FiX onClick={handleModalClose} className="modalCloseButton" />{' '}
        <div>
          <h3>ОФІЦІЙНИЙ ПРЕДСТАВНИК</h3>
          <p>ФОП РОМАНЕНКО АНТОНІНА ЮРІЇВНА</p>
          <p>067 232 4593</p>
        </div>
        <div>
          <h3>ДОСТАВКА ТА ОТРИМАННЯ</h3>
          <p>
            ДОСТАВКА ТОВАРІВ ПО КИЄВУ ТА ПРИМІСЬКІЙ ЗОНІ ЗДІЙСНЮЄТЬСЯ КУР'ЄРОМ
            ІНТЕРНЕТ-МАГАЗИНУ NOTFOREVERYONE. ДОСТАВКА ЗАМОВЛЕНИХ ТОВАРІВ
            ЗДІЙСНЮЄТЬСЯ В ДЕНЬ ЗАМОВЛЕННЯ АБО НА НАСТУПНИЙ.
          </p>
          <p>
            ЗАМОВЛЕННЯ МОЖНА ЗАБРАТИ САМОСТІЙНО ЗА АДРЕСОЮ ЄВГЕНА ЧЕКАЛЕНКО, 43
            А. ПН - СБ З 11.00 ДО 19.00
          </p>
        </div>
        <div>
          <h3>СПОСОБИ ОПЛАТИ</h3>
          <p>• БАНКІВСЬКОЮ КАРТОЮ НА САЙТІ</p>
          <p>• ОПЛАТА ГОТІВКОЮ В МАГАЗИНІ</p>
        </div>
        <div>
          <h3>ОБМІН ТА ПОВЕРНЕННЯ</h3>
          <p>
            ЯКЩО ВАМ НЕ ПІДІЙШОВ РОЗМІР АБО МОДЕЛЬ, ВИ МОЖЕТЕ ЛЕГКО ОБМІНЯТИ АБО
            ПОВЕРНУТИ ТОВАР ПРОТЯГОМ 14 ДНІВ З ДНЯ ПОКУПКИ. ДЛЯ ОБМІНУ ВАМ
            НЕОБХІДНО СПОЧАТКУ ОФОРМИТИ ПОВЕРНЕННЯ ПОПЕРЕДНЬОГО ТОВАРУ.
          </p>
          <p>
            <span>ЩОБ ОФОРМИТИ ПОВЕРНЕННЯ ВАМ НЕОБХІДНО ЗАПОВНИТИ:</span> <br />{' '}
            • ЗАЯВУ ПРО ПОВЕРНЕННЯ ТОВАРУ ІЗ ЗАЗНАЧЕННЯМ ПРИЧИНИ; <br /> • АКТ
            ПОВЕРНЕННЯ ТОВАРУ.
          </p>
          <p>
            <span>ДОДАТКОВО НЕОБХІДНО НАДАТИ:</span> <br /> • ДОКУМЕНТ, ЩО
            ПІДТВЕРДЖУЄ ПОКУПКУ ТОВАРУ: ТОВАРНУ НАКЛАДНУ АБО КАСОВИЙ ЧЕК; <br />{' '}
            • ДОКУМЕНТ, ЩО ЗАСВІДЧУЄ ВАШУ ОСОБУ; <br /> • РОЗПИСКУ ПРО ОТРИМАННЯ
            ПОВЕРНУТИХ КОШТІВ (В РАЗІ ГОТІВКОВОГО РОЗРАХУНКУ).
          </p>
          <p>
            <span>ВИМОГИ ДО ТОВАРІВ, ЩО ПІДЛЯГАЮТЬ ПОВЕРНЕННЮ:</span> <br /> ДЛЯ
            ПОВЕРНЕННЯ ВАМ НЕОБХІДНО НАДАТИ ТОВАР В ЙОГО ПЕРВІСНОМУ ВИГЛЯДІ: БЕЗ
            ПОШКОДЖЕНЬ І СЛІДІВ ВИКОРИСТАННЯ, З ОРИГІНАЛЬНИМИ ЛЕЙБАМИ, БИРКАМИ І
            В ОРИГІНАЛЬНІЙ УПАКОВЦІ. ЯКЩО ТОВАР БУВ ПРИДБАНИЙ В КОМПЛЕКТІ, ВІН
            ПОВИНЕН БУТИ ПОВЕРНУТИЙ В ПОВНОМУ СКЛАДІ. ПРАВИЛА ПОВЕРНЕННЯ
            ТОВАРІВ, ПРИДБАНИХ В ІНТЕРНЕТ-МАГАЗИНІ NOT FOR EVERYONE, РЕГУЛЮЮТЬСЯ
            ЗАКОНОМ УКРАЇНИ «ПРО ЗАХИСТ ПРАВ СПОЖИВАЧІВ». МИ ЗАЛИШАЄМО ЗА СОБОЮ
            ПРАВО НЕ ПРИЙМАТИ ПОВЕРНУТІ ТОВАРИ, ЯКЩО ВОНИ БУЛИ ДОСТАВЛЕНІ В НАШ
            МАГАЗИН ПІСЛЯ ЗАКІНЧЕННЯ 14-ДЕННОГО ТЕРМІНУ, АБО ЯКЩО СТАН ТОВАРІВ
            ВІДРІЗНЯЄТЬСЯ ВІД ТОГО, В ЯКОМУ ВИ ЇХ ОТРИМАЛИ.
          </p>
          <p>
            <span>ПОВЕРНЕННЯ КОШТІВ:</span> <br />
            ПІСЛЯ ОТРИМАННЯ ПОВЕРНЕННЯ І ПІДТВЕРДЖЕННЯ, ЩО ВИМОГИ ДО ПОВЕРНЕННЮ
            ТОВАРИ ДОТРИМАНІ, МИ ПОВЕРНЕМО ВАМ ГРОШІ. ПОВЕРНЕННЯ ГРОШЕЙ
            ЗДІЙСНЮЄТЬСЯ ТИМ ЖЕ СПОСОБОМ, ЯКИМ ВИ ОПЛАТИЛИ ЗАМОВЛЕННЯ. ЗВЕРНІТЬ
            УВАГУ, ЩО ПРИ ОПЛАТІ БАНКІВСЬКОЮ КАРТОЮ ТЕРМІН ПОВЕРНЕННЯ ГРОШОВИХ
            КОШТІВ ЗАЛЕЖИТЬ ВІД ПОЛІТИКИ УСТАНОВИ, ЩО ВИПУСТИВ КАРТУ.
          </p>
        </div>
      </div>
    </div>
  );
}

export default InfoModal;

import { FaViber, FaWhatsapp } from 'react-icons/fa';
import { FiPhoneCall, FiX } from 'react-icons/fi';
import { RiTelegramLine } from 'react-icons/ri';
import Select from 'react-select';
import WayForPayButton from './WayForPayButton';

function ProductModal({
  orderModal,
  consultationModal,
  helpModal,
  isSent,
  name,
  handleChangeName,
  phone,
  handleChangePhone,
  deliveryOption,
  handleDeliveryOptionChange,
  selectedCity,
  handleCityChange,
  handleCityInputChange,
  filteredCities,
  selectedWarehouse,
  handleWarehouseChange,
  handleWarehouseInputChange,
  filteredWarehouses,
  paymentOption,
  setPaymentOption,
  comment,
  handleChangeComment,
  handleSubmitOrder,
  product,
  handleSubmitConsultation,
  handleModalClose,
}) {
  if (product[0] && product[0].instock === '0') {
    setPaymentOption('у магазині');
  }

  return (
    <>
      {(orderModal || consultationModal || helpModal) && (
        <div className="modalBackground">
          <div className="modal">
            {helpModal && (
              <div className="buttonHelp__messengers">
                {/* <a href="/" target="_blank">
                  <RiTelegramLine />
                  Telegram
                </a> */}
                <a href="https://wa.me/380679463181" target="_blank">
                  <FaWhatsapp />
                  Whatsapp
                </a>
                {/* <a href="viber://add?number=380679463181" target="_blank">
                  <FaViber />
                  Viber
                </a> */}
                <a href="tel:+380442883300">
                  <FiPhoneCall /> Зателефонувати
                </a>
              </div>
            )}
            {!isSent &&
              ((orderModal && <h3>Оформити замовлення</h3>) ||
                (consultationModal && <h3>Запитати лікаря</h3>))}
            {!isSent && !helpModal && (
              <input
                type="text"
                value={name}
                onChange={(e) => {
                  const nameRegex = /^[a-zA-Zа-яА-ЯіІєЄїЇ'’\s]*$/;
                  if (nameRegex.test(e.target.value)) {
                    handleChangeName(e);
                  }
                }}
                placeholder="Ім'я"
                required
              />
            )}
            {!isSent && !helpModal && (
              <input
                type="tel"
                value={phone}
                onChange={(e) => {
                  const phoneRegex = /^[0-9+]*$/;
                  if (phoneRegex.test(e.target.value)) {
                    handleChangePhone(e);
                  }
                }}
                placeholder="Номер телефону"
                required
              />
            )}

            {!isSent && orderModal && product[0].instock === '1' && (
              <select
                className="delivery"
                value={deliveryOption}
                onChange={handleDeliveryOptionChange}>
                <option value="Самовивіз">Самовивіз</option>
                <option value="Нова Пошта">Нова Пошта</option>
              </select>
            )}

            {!isSent && orderModal && deliveryOption === 'Нова Пошта' && (
              <Select
                id="city"
                value={selectedCity}
                onChange={handleCityChange}
                onInputChange={handleCityInputChange}
                options={filteredCities}
                placeholder="Виберіть місто"
                noOptionsMessage={() => 'Нічого не знайдено'}
              />
            )}
            {!isSent && orderModal && deliveryOption === 'Нова Пошта' && (
              <Select
                id="city"
                value={selectedWarehouse}
                onChange={handleWarehouseChange}
                onInputChange={handleWarehouseInputChange}
                options={filteredWarehouses}
                placeholder="Виберіть відділення"
                noOptionsMessage={() => 'Нічого не знайдено'}
              />
            )}

            {!isSent && orderModal && product[0].instock === '1' && (
              <select
                value={paymentOption}
                onChange={(e) => setPaymentOption(e.target.value)}>
                <option value="онлайн">Оплата онлайн</option>
                <option value="у магазині">Оплата у магазині</option>
              </select>
            )}

            {!isSent && !helpModal && (
              <textarea
                value={comment}
                rows="6"
                onChange={handleChangeComment}
                placeholder={
                  orderModal ? 'Коментар' : consultationModal ? 'Питання' : ''
                }></textarea>
            )}

            {isSent &&
              ((orderModal && (
                <>
                  <h4>Дякуємо за замовлення!</h4>
                  {paymentOption == 'онлайн' && (
                    <WayForPayButton
                      name={name}
                      phone={phone}
                      product={product}
                    />
                  )}
                </>
              )) ||
                (consultationModal && <h4>Дякуємо за запитання!</h4>))}
            {!isSent && !helpModal && (
              <button
                disabled={!phone || !name}
                onClick={
                  orderModal
                    ? () =>
                        handleSubmitOrder(
                          product[0].sale_price > 1
                            ? product[0].sale_price
                            : product[0].price
                        )
                    : consultationModal
                    ? handleSubmitConsultation
                    : () => {}
                }
                style={{ opacity: !phone || !name ? 0.5 : 1 }}>
                {orderModal
                  ? 'Оформити замовлення'
                  : consultationModal
                  ? 'Запитати'
                  : ''}
              </button>
            )}
            <FiX onClick={handleModalClose} className="modalCloseButton" />
          </div>
        </div>
      )}
    </>
  );
}

export default ProductModal;

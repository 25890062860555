import axios from 'axios';
import { useState, useEffect } from 'react';

const WayForPayButton = ({ name, phone, product }) => {
  const [buttonHtml, setButtonHtml] = useState('');

  const productName = product[0].brand + ' ' + product[0].model;
  const productPrice =
    product[0].sale_price > 1 ? product[0].sale_price : product[0].price;

  useEffect(() => {
    // отправляем данные на сервер при загрузке компонента
    const formData = new FormData();
    formData.append('name', name);
    formData.append('phone', phone);
    formData.append('product', productName);
    formData.append('summ', productPrice);

    axios
      .post('https://notforeveryone.ua/payment.php', formData)
      .then((response) => {
        const html = response.data;
        setButtonHtml(html);
      })
      .catch((error) => console.error(error));
  }, []);

  return <div dangerouslySetInnerHTML={{ __html: buttonHtml }}></div>;
};

export default WayForPayButton;

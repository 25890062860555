import Collapsible from 'react-collapsible';
import './Terms.scss';
import { RxCaretDown, RxCaretUp } from 'react-icons/rx';
import { FaDollarSign } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

function Terms() {
  return (
    <div className="Container Container__custom">
      <section className="Terms">
        <div className="Terms__list">
          <Collapsible
            trigger={
              <h3>
                Доставка та отримання <RxCaretDown className="buttonPlus" />
                <RxCaretUp className="buttonMinus" />
              </h3>
            }
          >
            <p>
              Доставка товарів по Києву та приміській зоні здійснюється кур'єром
              інтернет-магазину Notforeveryone . Доставка замовлених товарів
              здійснюється в день замовлення або на наступний.
            </p>
            <br />
            <p>
              Замолвлення можно забрати самостійно за адресою Євгена Чекаленко
              43 а . З пн - сб з 11.00 до 19.00
            </p>
          </Collapsible>
          <Collapsible
            trigger={
              <h3>
                Способи оплати <RxCaretDown className="buttonPlus" />
                <RxCaretUp className="buttonMinus" />
              </h3>
            }
          >
            <p>- Банківською картою на сайті</p>
            <p>- Оплата готівкою в магазині</p>
          </Collapsible>
          <Collapsible
            trigger={
              <h3>
                Обмін та повернення <RxCaretDown className="buttonPlus" />
                <RxCaretUp className="buttonMinus" />
              </h3>
            }
          >
            <p>
              Якщо вам не підійшов розмір або модель, ви можете легко обміняти
              або повернути товар протягом 14 днів з дня покупки.
            </p>
            <p>
              Для обміну вам необхідно спочатку оформити повернення попереднього
              товару.
            </p>
            <br />
            <p>
              <h3>Як оформити повернення Вам необхідно заповнити:</h3>
            </p>
            <p> • заяву про повернення товару із зазначенням причини;</p>
            <p> • акт повернення товару.</p>
            <br />
            <p>
              <h3>Додатково необхідно надати:</h3>
            </p>
            <p>
              {' '}
              • документ, що підтверджує покупку товару: товарну накладну або
              касовий чек;
            </p>
            <p> • документ, що засвідчує Вашу особу;</p>
            <p>
              {' '}
              • розписку про отримання повернутих коштів (в разі готівкового
              розрахунку).
            </p>
            <br />
            <p>
              <h3>Вимоги до товарів, що підлягають поверненню:</h3>
            </p>
            <p>
              Для повернення вам необхідно надати товар в його первісному
              вигляді: без пошкоджень і слідів використання, з оригінальними
              лейбами, бирками і в оригінальній упаковці. Якщо товар був
              придбаний в комплекті, він повинен бути повернутий в повному
              складі. Правила повернення товарів, придбаних в інтернет-магазині
              NOT FOR EVERYONE, регулюються Законом України «Про захист прав
              споживачів». Ми залишаємо за собою право не приймати повернуті
              товари, якщо вони були доставлені в наш магазин після закінчення
              14-денного терміну, або якщо стан товарів відрізняється від того,
              в якому ви їх отримали.{' '}
            </p>
            <br />
            <p>
              <h3>Повернення коштів:</h3>
            </p>
            <p>
              Після отримання повернення і підтвердження, що вимоги до
              поверненню товари дотримані, ми повернемо вам гроші. Повернення
              грошей здійснюється тим же способом, яким ви оплатили замовлення.
              Зверніть увагу, що при оплаті банківською картою термін повернення
              грошових коштів залежить від політики установи, що випустив карту.
            </p>
          </Collapsible>
        </div>
      </section>
    </div>
  );
}

export default Terms;

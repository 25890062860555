import { FiChevronLeft } from 'react-icons/fi';
import './Brands.scss';
import BrandsList from './BrandsList';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

function Brands({ brands, handleBrandClick, currentBrand, clearCurrentBrand }) {
  return (
    <div className="Container Container__custom">
      <section className="Brands">
        <Helmet>
          <title>Not For Everyone - бренди</title>
        </Helmet>
        <div className={currentBrand ? `BrandsList active` : `BrandsList `}>
          <ul>
            {brands.map((brand) => {
              return (
                <BrandsList
                  key={brand.id}
                  brand={brand}
                  handleBrandClick={handleBrandClick}
                  disabled={brand.id !== currentBrand.id}
                />
              );
            })}
          </ul>
        </div>
        <div className={currentBrand ? `BrandsAbout active` : `BrandsAbout`}>
          <div className="BrandsAbout__about">
            <div className="line"></div>
            <div className="BrandsAbout__about__heading">
              <FiChevronLeft
                className="arrow"
                onClick={() => {
                  clearCurrentBrand();
                }}
              />

              <h1>{currentBrand.name}</h1>
            </div>
            <div
              className="BrandsAbout__about__description"
              style={{ whiteSpace: 'pre-line' }}
            >
              <p>{currentBrand.description}</p>
            </div>
          </div>
          <div className="BrandsAbout__img">
            <img
              src={`https://notforeveryone.ua/uploads/brands/${currentBrand.image}`}
              alt={currentBrand.name}
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Brands;

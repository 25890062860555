import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Header from './components/Header/Header';
import Catalog from './components/Catalog/Catalog';
import Product from './components/Product/Product';
import Space from './components/Space/Space';
import Services from './components/Services/Services';
import Contacts from './components/Contacts/Contacts';
import Brands from './components/Brands/Brands';
import NotFound from './components/NotFound';
import Terms from './components/Terms/Terms';
import { fetchBrands, fetchCategories } from './tools/api';
import './App.scss';

function App() {
  const isScreenMobile = useMediaQuery({ maxWidth: 750 });
  const [state, setState] = useState({
    brands: [],
    currentBrand: '',
    categories: [],
    searchQuery: '',
    searchSubmit: false,
  });

  //back from products to catalog
  const [fromProduct, setFromProduct] = useState(false);

  //wish list
  const [filterWish, setFilterWish] = useState([]);
  const [wishList, setWishList] = useState(
    () => JSON.parse(localStorage.getItem('wish')) || []
  );

  useEffect(() => {
    localStorage.setItem('wish', JSON.stringify(wishList));
  }, [wishList]);

  const handleWishList = (wish) => {
    if (wishList.includes(wish)) {
      setWishList((wishList) => wishList.filter((b) => b !== wish));
    } else {
      setWishList((wishList) => wishList.concat(wish));
    }
  };

  const handleFilterWish = (wishList) => {
    if (filterWish === wishList) {
      setFilterWish([]);
    } else {
      setFilterWish(wishList);
    }
  };
  //

  //filter active
  const [filterActive, setFilterActive] = useState(false);

  const handleFilterActive = (state) => {
    state ? setFilterActive(true) : setFilterActive(false);
  };

  const [filterToggle, setFilterToggle] = useState(false);

  const handleFilterToggle = () => {
    setFilterToggle(!filterToggle);
  };
  //

  const handleSearch = (event) => {
    setState((prevState) => ({
      ...prevState,
      searchQuery: event.target.value,
    }));
  };

  const handleSearchSubmit = (reset) => {
    reset
      ? setState((prevState) => ({
          ...prevState,
          searchSubmit: false,
        }))
      : setState((prevState) => ({
          ...prevState,
          searchSubmit: true,
        }));
  };

  const handleSearchClean = () => {
    setState((prevState) => ({
      ...prevState,
      searchQuery: '',
    }));
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const resBrands = await fetchBrands();
        const resCategories = await fetchCategories();

        setState({
          brands: resBrands.data,
          categories: resCategories.data,
          currentBrand: !isScreenMobile ? resBrands.data[0] || {} : '',
        });
      } catch (error) {
        console.log(error);
      }
    }

    fetchData();
  }, []);

  const handleBrandClick = (brandId) => {
    const selectedBrand = state.brands.find((brand) => brand.id === brandId);
    setState({ ...state, currentBrand: selectedBrand });
  };

  const clearCurrentBrand = () => {
    setState({ ...state, currentBrand: '' });
  };

  return (
    <div className="App">
      <BrowserRouter>
        <Header
          searchQuery={state.searchQuery}
          handleSearch={handleSearch}
          handleSearchSubmit={handleSearchSubmit}
          handleSearchClean={handleSearchClean}
          wishList={wishList}
          filterWish={filterWish}
          handleFilterWish={handleFilterWish}
          filterActive={filterActive}
          handleFilterToggle={handleFilterToggle}
        />
        <Routes>
          <Route
            key="catalog"
            exact
            path="/"
            element={
              <Catalog
                brands={state.brands}
                categories={state.categories}
                searchQuery={state.searchQuery}
                handleSearch={handleSearch}
                searchSubmit={state.searchSubmit}
                handleSearchClean={handleSearchClean}
                handleSearchSubmit={handleSearchSubmit}
                filterWish={filterWish}
                wishList={wishList}
                handleFilterWish={handleFilterWish}
                handleFilterActive={handleFilterActive}
                filterActive={filterActive}
                filterToggle={filterToggle}
                handleFilterToggle={handleFilterToggle}
                fromProduct={fromProduct}
                setFromProduct={setFromProduct}
              />
            }
          />
          <Route
            key="product"
            exact
            path="/product/:productId"
            element={
              <Product
                wishList={wishList}
                handleWishList={handleWishList}
                setFromProduct={setFromProduct}
              />
            }
          />
          <Route key="space" exact path="/space" element={<Space />} />
          <Route key="services" exact path="/services" element={<Services />} />
          <Route key="contacts" exact path="/contacts" element={<Contacts />} />
          <Route key="Terms" exact path="/terms" element={<Terms />} />
          <Route
            key="brands"
            exact
            path="/brands"
            element={
              <Brands
                brands={state.brands}
                handleBrandClick={handleBrandClick}
                currentBrand={state.currentBrand}
                clearCurrentBrand={clearCurrentBrand}
              />
            }
          />
          <Route key="notfound" path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

import { useState } from 'react';
import ServicesList from './ServicesList';
import servicesInfo from './servicesInfo';
import { FiChevronLeft } from 'react-icons/fi';
import { useMediaQuery } from 'react-responsive';
import './Services.scss';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

function Services() {
  const services = servicesInfo;
  const isScreenMobile = useMediaQuery({ maxWidth: 750 });

  const [currentService, setCurrentService] = useState(
    !isScreenMobile ? services[0] || {} : ''
  );

  const handleServiceClick = (service) => {
    setCurrentService(service);
  };

  const clearCurrentService = () => {
    setCurrentService('');
  };

  return (
    <div className="Container Container__custom">
      <section className="Services">
        <Helmet>
          <title>Not For Everyone - послуги</title>
        </Helmet>
        <div
          className={currentService ? `ServicesList active` : `ServicesList`}
        >
          <ul>
            {services.length > 0
              ? services.map((service, index) => {
                  return (
                    <ServicesList
                      key={index}
                      service={service}
                      handleServiceClick={() => handleServiceClick(service)}
                      disabled={service.name !== currentService.name}
                    />
                  );
                })
              : ''}
          </ul>
        </div>
        {currentService && (
          <div
            className={
              currentService ? `ServicesAbout active` : `ServicesAbout`
            }
          >
            <div className="ServicesAbout__about">
              <div className="line"></div>
              <div className="ServicesAbout__about__heading">
                <FiChevronLeft
                  className="arrow"
                  onClick={clearCurrentService}
                />
                <h1>{currentService.name}</h1>
              </div>
              <div
                className="ServicesAbout__about__description"
                style={{ whiteSpace: 'pre-line' }}
              >
                <p>{currentService.description}</p>
              </div>
            </div>
            <div className="ServicesAbout__img">
              <img src={currentService.img} alt={currentService.name} />
            </div>
          </div>
        )}
      </section>
    </div>
  );
}

export default Services;

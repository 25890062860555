import React, { useState, useEffect } from 'react';
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from 'react-icons/fa';
import './Catalog.scss';
import Filters from './Filters';
import Products from './Products';
import { fetchProducts } from '../../tools/api';
import { Helmet } from 'react-helmet';

const Catalog = ({
  brands,
  categories,
  searchQuery,
  searchSubmit,
  handleSearchSubmit,
  handleSearchClean,
  filterWish,
  wishList,
  handleFilterWish,
  handleFilterActive,
  filterActive,
  filterToggle,
  handleFilterToggle,
  fromProduct,
  setFromProduct,
}) => {
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [filterBrands, setFilterBrands] = useState([]);
  const [filterCategories, setFilterCategories] = useState([]);
  const [filterPrice, setFilterPrice] = useState('');
  const [filterSort, setFilterSort] = useState('спочатку нові');
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [searchedQuery, setSearchedQuery] = useState('');

  const addUniqueProducts = (newProducts) => {
    setProducts((prevProducts) => {
      const newProductsIds = newProducts.map((product) => product.id);
      const filteredProducts = prevProducts.filter(
        (product) => !newProductsIds.includes(product.id)
      );
      return [...filteredProducts, ...newProducts];
    });
  };

  const fetchProductsData = async (param) => {
    if (
      filterBrands.length ||
      filterCategories.length ||
      filterPrice.length ||
      filterSort !== 'спочатку нові'
    ) {
      handleFilterActive(true);
    } else {
      handleFilterActive(false);
    }

    if (param === 'filter' || searchSubmit === true || filterWish.length) {
      setPage(1);
    }
    setShouldUpdate(false);
    handleSearchSubmit(1);
    let url = `page=${page}`;

    if (!filterWish.length) {
      if (searchQuery) {
        url += `&search=${searchQuery}`;
        setSearchedQuery(searchQuery);
        localStorage.setItem('searchedQuery', JSON.stringify(searchQuery));
      } else {
        setSearchedQuery('');
      }

      if (filterBrands.length > 0) {
        url += `&brands=${filterBrands.join(',')}`;
        localStorage.setItem('filterBrands', JSON.stringify(filterBrands));
      }

      if (filterCategories.length > 0) {
        url += `&categories=${filterCategories.join(',')}`;
        localStorage.setItem(
          'filterCategories',
          JSON.stringify(filterCategories)
        );
      }

      if (filterPrice) {
        switch (filterPrice) {
          case 'до 10000':
            url += `&max_price=10000`;
            break;
          case '10000 - 20000':
            url += `&min_price=10000&max_price=20000`;
            break;
          case '20000 - 30000':
            url += `&min_price=20000&max_price=30000`;
            break;
          case 'більше 30000':
            url += `&min_price=30000`;
            break;
          default:
        }
        localStorage.setItem('filterPrice', JSON.stringify(filterPrice));
      }

      if (filterSort !== 'спочатку нові') {
        if (filterSort === 'спочатку дорожчі') {
          url += `&sort=maxprice`;
        } else {
          url += `&sort=minprice`;
        }
        localStorage.setItem('filterSort', JSON.stringify(filterSort));
      }
    }

    if (filterWish.length) {
      url += `&wish=${filterWish}`;
      localStorage.setItem('filterWish', JSON.stringify(filterWish));
    }

    try {
      const res = await fetchProducts(url);

      setTotalPages(res.headers['x-pagination-page-count']);
      localStorage.setItem(
        'totalPages',
        JSON.stringify(res.headers['x-pagination-page-count'])
      );
      localStorage.setItem('page', JSON.stringify(page));

      if (page === 1) {
        setProducts(res.data);
      } else {
        addUniqueProducts(res.data);
      }
    } catch (error) {
      console.log(error);
      setProducts([]);
    }
  };

  useEffect(() => {
    if (!fromProduct) {
      localStorage.setItem('products', JSON.stringify(products));
    }
  }, [products]);

  useEffect(() => {
    if (!fromProduct) {
      clearLocalStorageFromProduct();
      fetchProductsData();
    }
  }, [page, shouldUpdate, searchSubmit, filterWish]);

  useEffect(() => {
    if (fromProduct) {
      const storedProducts = localStorage.getItem('products');
      if (storedProducts) {
        setProducts(JSON.parse(storedProducts));
      }
      const storedSearchedQuery = localStorage.getItem('searchedQuery');
      if (storedSearchedQuery) {
        setSearchedQuery(JSON.parse(storedSearchedQuery));
      }
      const storedFilterBrands = localStorage.getItem('filterBrands');
      if (storedFilterBrands) {
        setFilterBrands(JSON.parse(storedFilterBrands));
      }
      const storedFilterCategories = localStorage.getItem('filterCategories');
      if (storedFilterCategories) {
        setFilterCategories(JSON.parse(storedFilterCategories));
      }
      const storedFilterPrice = localStorage.getItem('filterPrice');
      if (storedFilterPrice) {
        setFilterPrice(JSON.parse(storedFilterPrice));
      }
      const storedFilterSort = localStorage.getItem('filterSort');
      if (storedFilterSort) {
        setFilterSort(JSON.parse(storedFilterSort));
      }
      const storedFilterWish = localStorage.getItem('filterWish');
      if (storedFilterWish) {
        handleFilterWish(JSON.parse(storedFilterWish));
      }
      const storedPage = localStorage.getItem('page');
      if (storedPage) {
        setPage(JSON.parse(storedPage));
      }
      const storedTotalPages = localStorage.getItem('totalPages');
      if (storedTotalPages) {
        setTotalPages(JSON.parse(storedTotalPages));
      }
      setFromProduct(false);
    }
    // else {
    //   clearLocalStorageFromProduct();
    // }
  }, [fromProduct]);

  const clearLocalStorageFromProduct = () => {
    localStorage.removeItem('products');
    localStorage.removeItem('searchedQuery');
    localStorage.removeItem('filterBrands');
    localStorage.removeItem('filterCategories');
    localStorage.removeItem('filterPrice');
    localStorage.removeItem('filterSort');
    localStorage.removeItem('filterWish');
    localStorage.removeItem('storedPage');
    localStorage.removeItem('totalPages');
  };

  const handleLoadMore = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handleFilterBrands = (brand) => {
    if (filterBrands.includes(brand)) {
      setFilterBrands((prevBrands) => prevBrands.filter((b) => b !== brand));
    } else {
      setFilterBrands((prevBrands) => [...prevBrands, brand]);
    }
  };

  const handleFilterCategories = (category) => {
    if (filterCategories.includes(category)) {
      setFilterCategories((prevCategories) =>
        prevCategories.filter((c) => c !== category)
      );
    } else {
      setFilterCategories((prevCategories) => [...prevCategories, category]);
    }
  };

  const handleFilterPrice = (price) => {
    !price ? setFilterPrice('') : setFilterPrice(price);
  };

  const handleFilterSort = (sort) => {
    setFilterSort(sort);
  };

  const resetFilters = () => {
    clearLocalStorageFromProduct();
    setFilterBrands([]);
    setFilterCategories([]);
    setFilterPrice('');
    setFilterSort('спочатку нові');
    setPage(1);
    setShouldUpdate(true);
  };

  const handleSearchResultClean = () => {
    handleSearchClean();
    setSearchedQuery('');
    setShouldUpdate(true);
  };

  const imageVariants = {
    initial: {
      scale: 1,
      transition: { duration: 0.35 },
    },
    hover: {
      scale: 1.05,
      transition: { duration: 0.35 },
    },
  };

  return (
    <div className="Container">
      <section className="Catalog">
        <Helmet>
          <title>Not For Everyone</title>
        </Helmet>
        {!filterWish.length ? (
          <Filters
            key={wishList}
            fetchProductsData={fetchProductsData}
            brands={brands}
            categories={categories}
            filterBrands={filterBrands}
            filterCategories={filterCategories}
            filterPrice={filterPrice}
            filterSort={filterSort}
            handleFilterBrands={handleFilterBrands}
            handleFilterCategories={handleFilterCategories}
            handleFilterPrice={handleFilterPrice}
            handleFilterSort={handleFilterSort}
            resetFilters={resetFilters}
            filterToggle={filterToggle}
            handleFilterToggle={handleFilterToggle}
            filterActive={filterActive}
          />
        ) : (
          ''
        )}
        <Products
          fetchProductsData={fetchProductsData}
          page={page}
          products={products}
          totalPages={totalPages}
          searchedQuery={searchedQuery}
          handleSearchResultClean={handleSearchResultClean}
          handleLoadMore={handleLoadMore}
          filterWish={filterWish}
          wishList={wishList}
          handleFilterWish={handleFilterWish}
        />
      </section>
    </div>
  );
};

export default Catalog;

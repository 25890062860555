import GoogleMapReact from 'google-map-react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import './Contacts.scss';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

function Contacts() {
  const defaultProps = {
    center: {
      lat: 50.4402758,
      lng: 30.5164466,
    },
    zoom: 17,
  };
  const mapStyles = [
    {
      featureType: 'all',
      elementType: 'all',
      stylers: [
        {
          saturation: -50,
        },
        {
          gamma: 0.5,
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
  ];
  const Marker = () => (
    <div style={{ fontSize: '24px' }}>
      <FaMapMarkerAlt style={{ fill: 'red' }} />
    </div>
  );
  return (
    <div className="Container Container__custom">
      <section className="Contacts">
        <Helmet>
          <title>Not For Everyone - контакти</title>
        </Helmet>
        <div className="Contacts__adress">
          <p>
            <b>Not for Everyone</b>
          </p>
          <div>
            <p>Київ</p>
            <a href="https://goo.gl/maps/b6DoU42gwzms7rgv5" target="_blank">
              вулиця Євгена Чикаленка (Пушкінська), 43 А
            </a>
          </div>
          <div>
            <a href="tel:+380442883300">044 288 33 00</a>
            <a href="tel:+380672324593">067 232 45 93</a>
          </div>
          <p><a href="mailto:nfe.optics@gmail.com">nfe.optics@gmail.com</a></p>
          <div>
            <p>Магазин:</p>
            <p>ПН - СБ 11:00 - 19:00</p>
          </div>
          <div>
            <p>Офтальмолог:</p>
            <p>ПН - ПТ 14:00 - 18:00</p>
          </div>
        </div>
        <div className="Contacts__map">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyC4bWaJVkyUpQH_ivaASXWt1QaKAtm3JpE&amp',
            }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            options={{ styles: mapStyles }}
          >
            <Marker lat={50.4404} lng={30.5163} />
          </GoogleMapReact>
        </div>
      </section>
    </div>
  );
}

export default Contacts;

import { Link } from 'react-router-dom';
import './Products.scss';
import { FiX } from 'react-icons/fi';

function Products({
  products,
  imageVariants,
  page,
  totalPages,
  handleLoadMore,
  searchedQuery,
  handleSearchResultClean,
  filterWish,
  wishList,
  handleFilterWish,
}) {
  // Разбиваем массив товаров на массивы по 12 элемента
  const pages = products.reduce((acc, cur, i) => {
    const pageIndex = Math.floor(i / 12);
    if (!acc[pageIndex]) {
      acc[pageIndex] = [];
    }
    acc[pageIndex].push(cur);
    return acc;
  }, []);

  return (
    <div className={`ProductsPages ${filterWish && 'ProductsPages__full'}`}>
      {filterWish.length ? (
        <h1>
          Обрані товари
          <FiX
            className="buttonSearchClean"
            onClick={() => {
              handleFilterWish(wishList);
            }}
          />
        </h1>
      ) : (
        ''
      )}
      {!filterWish.length &&
        searchedQuery &&
        (products.length > 0 ? (
          <h1>
            Результат пошуку: {searchedQuery}{' '}
            <FiX
              className="buttonSearchClean"
              onClick={() => handleSearchResultClean()}
            />
          </h1>
        ) : (
          <h1>
            За запитом "{searchedQuery}" нічого не знайдено
            <FiX
              className="buttonSearchClean"
              onClick={() => handleSearchResultClean()}
            />
          </h1>
        ))}
      {pages.map((pageProducts, pageIndex) => (
        <div key={pageIndex} className="ProductsPages__Products">
          {pageProducts.map((product, index) => (
            <Link
              key={product.id}
              to={`/product/${product.id}`}
              style={{
                borderBottom: `2px solid ${
                  ['#f4b710', '#a0d1c7', '#f08bb8'][
                    Math.floor(Math.random() * 3)
                  ]
                }`,
              }}
            >
              <img
                src={`https://notforeveryone.ua/uploads/${
                  product.image_front
                    ? product.image_front
                    : product.image_detail
                    ? product.image_detail
                    : product.image_face
                }`}
                alt={product.name}
                variants={imageVariants}
                initial="initial"
              />
            </Link>
          ))}
        </div>
      ))}
      {page < totalPages
        ? products.length > 0 && (
            <button
              className="loadmore"
              onClick={() => handleLoadMore(page + 1)}
            >
              Завантажити ще
            </button>
          )
        : ''}
    </div>
  );
}

export default Products;

import selection from './img/selection.jpg';
import consultation from './img/consultation.jpg';
import testing from './img/testing.png';
import correction from './img/correction.jpg';
import manufacturing from './img/manufacturing.jpg';
import accessories from './img/accessories.jpg';

const servicesInfo = [
  {
    name: 'Підбір окулярів',
    description: `Це гарантовано високий рівень сервісу та максимальний комфорт для наших клієнтів. Якщо ви вже знаєте, що саме вам потрібно, маєте рецепт, не хочете витрачати час на детальне ознайомлення з асортиментом - ми швидко допоможемо підібрати саме ту ідеальну пару.`,
    img: selection,
  },
  {
    name: 'Консультація стиліста',
    description: `Наш стиліст - це фахівець, який допоможе підібрати окуляри, які підходять саме вам. Він врахує ваші переваги, особливості зовнішності та стиль, щоб допомогти вибрати найкращі окуляри для вашого комфорту та створення бездоганного образу.`,
    img: consultation,
  },
  {
    name: 'Перевірка зору',
    description: `Наш професійний офтальмолог здійснить повний медичний огляд зору. Ми перевіримо зір і зрозуміло розкажемо про стан вашої зорової системи. Завдяки цьому ви зможете отримати якісну допомогу і корекцію зору.`,
    img: testing,
  },
  {
    name: 'Консультація офтальмолога',
    description: `Досвідчений спеціаліст з увагою вислухає ваші потреби та особливості, складе найоптимальнішу схему корекції зору та підбере необхідні лінзи від провідних оптичних брендів Zeiss, Seiko, Le Perle.`,
    img: correction,
  },
  {
    name: 'Виготовлення окулярів за вашим рецептом',
    description: `Наші майстри оптики забезпечують досконале виготовлення окулярів, поєднуючи відточену роками майстерність та передові технології. Ми стежимо за кожним кроком в процесі роботи, щоб забезпечити найкращий результат.`,
    img: manufacturing,
  },
  {
    name: 'СУПУТНІ ТОВАРИ: АКСЕСУАРИ ДЛЯ ОКУЛЯРІВ',
    description: `Наш магазин оптики пропонує широкий вибір аксесуарів, які зроблять використання окулярів ще зручнішим та ефективнішим. У нас ви знайдете все необхідне для зберігання, чищення та догляду за вашими окулярами, щоб вони служили вам якомога довше і залишалися у найкращому стані.`,
    img: accessories,
  },
  // {
  //   name: 'Подарункові сертифікати',
  //   description: `Зробити ідеальний подарунок просто! Порадуйте своїх близьких та друзів сертифікатом від Not For Everyone. Його власник вибере окуляри, які стовідсотково будуть до вподоби, а приємні емоції від корисування будуть асоціюватися з вами.`,
  // },
];

export default servicesInfo;

{
  /* <div className="certificate">
  <p>Вибрати номінал:</p>
  <select name="select">
    <option value="1 500 ₴">1 500 ₴</option>
    <option value="5 000 ₴">5 000 ₴</option>
    <option value="10 000 ₴">10 000 ₴</option>
  </select>
  <button className="buttonApple">
    <FaDollarSign style={{ fill: '#fff' }} /> Купити
    <FaApplePay className="apple" />
  </button>
</div>; */
}

import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import { getRandomColor } from '../../tools/functions';

SwiperCore.use([Autoplay, Pagination]);

function ProductImages(props) {
  const images = [];
  let video = null;

  if (props.product && props.product[0]) {
    if (props.product[0].video) {
      video = props.product[0].video;
    }
    if (props.product[0].image_face) {
      images.push(props.product[0].image_face);
    }
    if (props.product[0].image_front) {
      images.push(props.product[0].image_front);
    }
    if (props.product[0].image_detail) {
      images.push(props.product[0].image_detail);
    }
    if (props.product[0].case) {
      images.push(`cases/${props.product[0].case}.jpg`);
    }
  }

  const autoplayConfig = video ? false : { delay: 3000 };

  return (
    <Swiper
      loop={true}
      initialSlide={0}
      autoplay={autoplayConfig}
      speed={1000}
      pagination={{
        clickable: true,
        renderBullet: (index, className) => {
          return `<span class="${className}" style="background-color: ${getRandomColor()}"></span>`;
        },
      }}
    >
      {video && (
        <SwiperSlide key="video">
          <video
            width="100%"
            height="100%"
            autoPlay
            loop
            muted
            playsInline
            webkit-playsinline
            style={{ objectFit: 'cover' }}
          >
            <source src={`https://notforeveryone.ua/uploads/${video}`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </SwiperSlide>
      )}
      {images.map((image) => (
        <SwiperSlide key={image}>
          <img
            src={`https://notforeveryone.ua/uploads/${image}`}
            alt="product"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default ProductImages;
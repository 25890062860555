import { useMediaQuery } from 'react-responsive';
import { motion } from 'framer-motion';
import { getRandomColor, hexToRgba } from '../../tools/functions';

function BrandsList({ brand, handleBrandClick, disabled }) {
  const BrandsListVariants = {
    hover: {
      scale: 1.1,
      cursor: 'pointer',
      textShadow: `8px 8px 0px ${hexToRgba(getRandomColor(), 1)}`, // generate a new random color on hover
      x: -16,
      y: -8,
      padding: '5px',
      transition: { duration: 0.35 },
    },
  };

  const isScreenMobile = useMediaQuery({ maxWidth: 800 });
  const variants = { ...BrandsListVariants };
  if (isScreenMobile) {
    delete variants.hover.x;
    delete variants.hover.y;
  }

  return (
    <motion.li
      key={brand.id}
      onClick={() => handleBrandClick(brand.id)}
      variants={BrandsListVariants}
      whileHover="hover"
      style={{
        pointerEvents: disabled ? 'auto' : 'none',
        color: disabled ? '#403f41' : `${hexToRgba(getRandomColor(), 1)}`,
      }}>
      {brand.name}
    </motion.li>
  );
}

export default BrandsList;

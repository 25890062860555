import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { bubble as Menu } from 'react-burger-menu';
import { FiMenu, FiSearch, FiX } from 'react-icons/fi';
import { getRandomColor, hexToRgba } from '../../tools/functions';
import './Nav.scss';
import instagram from './img/instagram.svg';
import tiktok from './img/TikTok.svg';

function Nav({
  searchQuery,
  handleSearch,
  handleSearchSubmit,
  handleSearchClean,
  handleSubmit,
}) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      // Блокируем скролл фона при открытом бургере
      document.body.style.overflow = 'hidden';
    } else {
      // Разблокируем скролл фона при закрытом бургере
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  const handleMenuToggle = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  // цвета для границ ссылок
  const colors = ['#f4b710', '#a0d1c7', '#f08bb8'];

  return (
    <nav className="Nav">
      <Menu
        isOpen={isOpen}
        onOpen={handleMenuToggle}
        onClose={handleMenuToggle}
        className="burgerMenu"
        left
        customCrossIcon={
          <FiX className="burgerClose" onClick={handleMenuToggle} />
        }
        customBurgerIcon={
          <FiMenu className="burgerIcon" onClick={handleMenuToggle} />
        }
      >
        <NavLink
          to="/"
          onClick={closeMenu}
          style={{ borderColor: `${getRandomColor()}` }}
        >
          Каталог
        </NavLink>
        <NavLink
          to="/brands"
          onClick={closeMenu}
          style={{ borderColor: `${getRandomColor()}` }}
        >
          Бренди
        </NavLink>
        <NavLink
          to="/services"
          onClick={closeMenu}
          style={{ borderColor: `${getRandomColor()}` }}
        >
          Послуги
        </NavLink>
        {/* <NavLink
          to="/space"
          onClick={closeMenu}
          style={{ borderColor: `${getRandomColor()}` }}
        >
          Простір
        </NavLink> */}
        <NavLink
          to="/contacts"
          onClick={closeMenu}
          style={{ borderColor: `${getRandomColor()}` }}
        >
          Контакти
        </NavLink>
        <div className="socials">
          <a
            href="https://www.instagram.com/notforeveryone.ua/"
            target="_blank"
          >
            <img src={instagram} alt="Instagram" />
          </a>
          <a href="https://www.tiktok.com/@notforeveryone.ua" target="_blank">
            <img src={tiktok} alt="TikTok" />
          </a>
        </div>
      </Menu>
      <ul>
        <li>
          <NavLink to="/" style={{ borderColor: `${getRandomColor()}` }}>
            Каталог
          </NavLink>
        </li>

        <li>
          {' '}
          <NavLink to="/brands" style={{ borderColor: `${getRandomColor()}` }}>
            Бренди
          </NavLink>
        </li>

        <li>
          <NavLink
            to="/services"
            style={{ borderColor: `${getRandomColor()}` }}
          >
            Послуги
          </NavLink>
        </li>

        {/* <li>
          <NavLink to="/space" style={{ borderColor: `${getRandomColor()}` }}>
            Простір
          </NavLink>
        </li> */}

        <li>
          {' '}
          <NavLink
            to="/contacts"
            style={{ borderColor: `${getRandomColor()}` }}
          >
            Контакти{' '}
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default Nav;

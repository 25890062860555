import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FiHeart, FiX, FiChevronLeft } from 'react-icons/fi';
import { FiMessageCircle, FiInfo } from 'react-icons/fi';
import { FaApplePay, FaDollarSign } from 'react-icons/fa';
import { FaGlasses } from 'react-icons/fa';
import { RxCaretDown, RxCaretUp } from 'react-icons/rx';
import './Product.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  fetchProduct,
  fetchSubmitConsultation,
  fetchSubmitOrder,
} from '../../tools/api';
import NotFound from '../NotFound';
import ProductModal from './ProductModal';
import ProductImages from './ProductImages';
import InfoModal from './InfoModal';

const API_KEY = '5d075710965f94aba379ef50e26288be';
const API_URL = 'https://api.novaposhta.ua/v2.0/json/';

function Product({ wishList, handleWishList, setFromProduct }) {
  const navigate = useNavigate();

  const handleGoBack = () => {
    setFromProduct(true);
    navigate(-1);
  };

  const [product, setProduct] = useState({});
  const params = useParams();
  const [helpModal, setHelpModal] = useState(false);
  const [orderModal, setOrderModal] = useState(false);
  const [consultationModal, setConsultationModal] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [paymentOption, setPaymentOption] = useState('онлайн');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [comment, setComment] = useState('');

  const [infoModal, setInfoModal] = useState(false);

  //begin show case image
  const [showCase, setShowCase] = useState(false);

  const handleSpoilerClick = () => {
    setShowCase(!showCase);
  };
  //end show case image

  //begin delivery options

  const [deliveryOption, setDeliveryOption] = useState('Самовивіз');

  const handleDeliveryOptionChange = (event) => {
    setDeliveryOption(event.target.value);
  };

  //end delivery options

  //begin nova poshta

  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [filteredCities, setFilteredCities] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState('');
  const [filteredWarehouses, setFilteredWarehouses] = useState([]);

  useEffect(() => {
    if (deliveryOption == 'Самовивіз') {
      setSelectedCity('');
      setSelectedWarehouse('');
    }
  }, [deliveryOption]);

  useEffect(() => {
    const getCities = async () => {
      const response = await axios.post(API_URL, {
        apiKey: API_KEY,
        modelName: 'Address',
        calledMethod: 'getCities',
        methodProperties: {
          FindByString: '',
          Language: 'ua',
        },
      });
      const cities = response.data.data.map((city) => ({
        label: city.Description,
        value: city.Ref,
      }));
      setCities(cities);
      setFilteredCities(cities);
    };
    getCities();
  }, []);

  const handleCityInputChange = (inputValue) => {
    if (inputValue === '') {
      setFilteredCities(cities);
      return;
    }
    const filteredCities = cities.filter((city) =>
      city.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredCities(filteredCities);
  };

  const handleCityChange = async (selectedOption) => {
    setSelectedCity(selectedOption);
    setSelectedWarehouse(null);
    const response = await axios.post(API_URL, {
      apiKey: API_KEY,
      modelName: 'AddressGeneral',
      calledMethod: 'getWarehouses',
      methodProperties: {
        CityRef: selectedOption.value,
        Language: 'ua',
      },
    });
    const warehouses = response.data.data.map((warehouse) => ({
      label: warehouse.Description,
      value: warehouse.Ref,
    }));
    setWarehouses(warehouses);
    setFilteredWarehouses(warehouses);
  };

  const handleWarehouseInputChange = (inputValue) => {
    if (inputValue === '') {
      setFilteredWarehouses(warehouses);
      return;
    }
    const filteredWarehouses = warehouses.filter((warehouse) =>
      warehouse.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredWarehouses(filteredWarehouses);
  };

  const handleWarehouseChange = (selectedOption) => {
    setSelectedWarehouse(selectedOption);
  };

  ///end nova poshta

  const handleModalClose = () => {
    setOrderModal(false);
    setConsultationModal(false);
    setHelpModal(false);
    setInfoModal(false);
    setIsSent(false);
  };

  const handleChangeName = (e) => {
    setName(e.target.value);
  };

  const handleChangePhone = (e) => {
    setPhone(e.target.value);
  };

  const handleChangeComment = (e) => {
    setComment(e.target.value);
  };

  useEffect(() => {
    window.gtag_report_conversion = function (price) {
      window.gtag('event', 'conversion', {
        send_to: 'AW-11114434910/I-8vCIu_jZEYEN6i47Mp',
        transaction_id: '',
        value: price,
        currency: 'UAH',
      });

      return false;
    };
  }, []);

  const handleSubmitOrder = (price) => {
    const city = selectedCity.label ? selectedCity.label : '';
    const warehouse = selectedWarehouse.label ? selectedWarehouse.label : '';

    fetchSubmitOrder(
      params.productId,
      phone,
      name,
      comment,
      city,
      warehouse,
      paymentOption
    )
      .then(() => {
        setIsSent(true);
        window.gtag_report_conversion(price);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmitConsultation = () => {
    fetchSubmitConsultation(params.productId, phone, name, comment)
      .then(() => {
        setIsSent(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchProduct(params.productId)
      .then((res) => {
        setProduct(res.data);
      })
      .catch((err) => console.log(err));
  }, [params.productId]);

  return (
    <div className="Container Container__custom">
      <section className="Product">
        {Object.keys(product).length > 0 && (
          <>
            <Helmet>
              <title>
                Not For Everyone - {product[0].brand} {product[0].model}
              </title>
            </Helmet>
            <div className="Product__images">
              <ProductImages product={product} />
            </div>

            <div className="Product__info">
              <div className="line"></div>
              <div className="Product__info__heading">
                <div className="buttonBack">
                  <FiChevronLeft onClick={handleGoBack} />
                </div>
                <h1>{product[0].brand}</h1>
              </div>
              <div className="Product__info__brand">
                <p>{product[0].description}</p>
              </div>
              <div className="Product__info__about">
                <ul>
                  {product[0].country && (
                    <li>Країна виробник: {product[0].country}</li>
                  )}
                  {product[0].material && (
                    <li>Матеріал: {product[0].material}</li>
                  )}
                  {product[0].color_frame && (
                    <li>Колір Оправи: {product[0].color_frame}</li>
                  )}
                  {product[0].color_lense && (
                    <li>Колір лінз: {product[0].color_lense}</li>
                  )}
                  {product[0].model && <li>Модель: {product[0].model}</li>}
                  {product[0].size && <li>Розмір: {product[0].size}</li>}
                </ul>
              </div>

              <div className="Product__info__price">
                <FiHeart
                  className={
                    wishList.includes(product[0].id)
                      ? 'buttonLike active'
                      : 'buttonLike'
                  }
                  onClick={() => handleWishList(product[0].id)}
                />
                <div className="Product__info__price__num">
                  {product[0].instock === '1' ? (
                    <>
                      {product[0].sale_price > 1 && (
                        <>
                          <span>
                            <small>
                              <s className="priceSmall">{product[0].price} ₴</s>
                            </small>
                          </span>
                        </>
                      )}
                      {product[0].sale_price > 1 ? (
                        <span>
                          {parseFloat(product[0].sale_price).toLocaleString(
                            'uk-UA',
                            {
                              useGrouping: true,
                            }
                          )}{' '}
                          ₴
                        </span>
                      ) : (
                        <span>
                          {parseFloat(product[0].price).toLocaleString(
                            'uk-UA',
                            {
                              useGrouping: true,
                            }
                          )}{' '}
                          ₴
                        </span>
                      )}
                    </>
                  ) : (
                    <span>Продано</span>
                  )}
                </div>
              </div>

              <button
                className="buttonApple"
                onClick={() => {
                  setOrderModal(!orderModal);
                }}
              >
                {product[0].instock === '1' ? (
                  <>
                    <FaDollarSign style={{ fill: '#fff' }} /> Купити
                  </>
                ) : (
                  'Замовити'
                )}
              </button>

              <button
                className="help button"
                onClick={() => {
                  setHelpModal(!helpModal);
                }}
              >
                <FiMessageCircle className="img" />
                Допомога
              </button>

              <button
                className="doctor button"
                onClick={() => {
                  setConsultationModal(!consultationModal);
                }}
              >
                <FaGlasses className="img" />
                Консультація офтальмолога
              </button>

              <button
                className="terms button"
                onClick={() => setInfoModal(!infoModal)}
              >
                <FiInfo className="img" /> Доставка та оплата
              </button>
            </div>
          </>
        )}
        {infoModal && <InfoModal handleModalClose={handleModalClose} />}
        <ProductModal
          {...{
            orderModal,
            consultationModal,
            helpModal,
            isSent,
            name,
            handleChangeName,
            phone,
            handleChangePhone,
            deliveryOption,
            handleDeliveryOptionChange,
            selectedCity,
            handleCityChange,
            handleCityInputChange,
            filteredCities,
            selectedWarehouse,
            handleWarehouseChange,
            handleWarehouseInputChange,
            filteredWarehouses,
            paymentOption,
            setPaymentOption,
            comment,
            handleChangeComment,
            handleSubmitOrder,
            product,
            handleSubmitConsultation,
            handleModalClose,
          }}
        />

        {/* {
          !product[0] ? (<NotFound />) : '' //if product not found return to NotFound
        } */}
      </section>
    </div>
  );
}

export default Product;

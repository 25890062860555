import { Helmet } from 'react-helmet';
import './Space.scss';
import image from './img/comingsoon.jpg';
import { useLocation } from 'react-router-dom';

function Space() {
  return (
    <div className="Container Container__custom">
      <section className="Space">
        <Helmet>
          <title>Not For Everyone - простір</title>
        </Helmet>
        <div
          className="Space__Comingsoon"
          style={{ backgroundImage: { image } }}
        >
          <h4>Coming soon</h4>
        </div>
      </section>
    </div>
  );
}

export default Space;

import { useEffect, useState } from 'react';
import { FiXCircle, FiX } from 'react-icons/fi';
import './Filters.scss';

function Filters({
  brands,
  filterBrands,
  handleFilterBrands,
  categories,
  filterCategories,
  handleFilterCategories,
  filterPrice,
  handleFilterPrice,
  filterSort,
  handleFilterSort,
  fetchProductsData,
  resetFilters,
  filterToggle,
  handleFilterToggle,
  filterActive,
}) {
  return (
    <div className={`Filters ${filterToggle ? 'toggled' : ''}`}>
      <h4>{filterActive && 'Є '}фільтри</h4>
      {filterToggle && (
        <FiX className="closeFilters" onClick={() => handleFilterToggle()} />
      )}
      <div className="Filters__options">
        <ul className="Filters__options__brands">
          <li className="optionsHeading">Бренди</li>
          {brands.map((brand) => (
            <li
              key={brand.id}
              className={filterBrands.includes(brand.name) ? 'active' : ''}
              style={
                filterBrands.includes(brand.name)
                  ? {
                      border: `1px solid ${
                        ['#f4b710', '#a0d1c7', '#f08bb8'][
                          Math.floor(Math.random() * 3)
                        ]
                      }`,
                    }
                  : {}
              }
              onClick={() => handleFilterBrands(brand.name)}
            >
              {brand.name}
              <FiX className="buttonClose" />
            </li>
          ))}
        </ul>
        <ul className="Filters__options__categories">
          <li className="optionsHeading">Категорії</li>
          {categories.map((category) => (
            <li
              key={category.id}
              className={
                filterCategories.includes(category.name) ? 'active' : ''
              }
              style={
                filterCategories.includes(category.name)
                  ? {
                      border: `1px solid ${
                        ['#f4b710', '#a0d1c7', '#f08bb8'][
                          Math.floor(Math.random() * 3)
                        ]
                      }`,
                    }
                  : {}
              }
              onClick={() => handleFilterCategories(category.name)}
            >
              {category.name}
              <FiX className="buttonClose" />
            </li>
          ))}
        </ul>
        <ul className="Filters__options__prices">
          <li className="optionsHeading">Ціни</li>
          <li
            className={filterPrice === 'до 10000' ? 'active' : ''}
            style={
              filterPrice.includes('до 10000')
                ? {
                    border: `1px solid ${
                      ['#f4b710', '#a0d1c7', '#f08bb8'][
                        Math.floor(Math.random() * 3)
                      ]
                    }`,
                  }
                : {}
            }
            onClick={() => handleFilterPrice('до 10000')}
          >
            до 10 000
          </li>
          <li
            className={filterPrice === '10000 - 20000' ? 'active' : ''}
            style={
              filterPrice.includes('10000 - 20000')
                ? {
                    border: `1px solid ${
                      ['#f4b710', '#a0d1c7', '#f08bb8'][
                        Math.floor(Math.random() * 3)
                      ]
                    }`,
                  }
                : {}
            }
            onClick={() => handleFilterPrice('10000 - 20000')}
          >
            10 000 - 20 000
          </li>
          <li
            className={filterPrice === '20000 - 30000' ? 'active' : ''}
            style={
              filterPrice.includes('20000 - 30000')
                ? {
                    border: `1px solid ${
                      ['#f4b710', '#a0d1c7', '#f08bb8'][
                        Math.floor(Math.random() * 3)
                      ]
                    }`,
                  }
                : {}
            }
            onClick={() => handleFilterPrice('20000 - 30000')}
          >
            20 000 - 30 000
          </li>
          <li
            className={filterPrice === 'більше 30000' ? 'active' : ''}
            style={
              filterPrice.includes('більше 30000')
                ? {
                    border: `1px solid ${
                      ['#f4b710', '#a0d1c7', '#f08bb8'][
                        Math.floor(Math.random() * 3)
                      ]
                    }`,
                  }
                : {}
            }
            onClick={() => handleFilterPrice('більше 30000')}
          >
            більше 30 000
          </li>
        </ul>
        <ul className="Filters__options__sort">
          <li className="optionsHeading">Сортування</li>
          <li
            className={filterSort.includes('спочатку нові') ? 'active' : ''}
            style={
              filterSort.includes('спочатку нові')
                ? {
                    border: `1px solid ${
                      ['#f4b710', '#a0d1c7', '#f08bb8'][
                        Math.floor(Math.random() * 3)
                      ]
                    }`,
                  }
                : {}
            }
            onClick={() => handleFilterSort('спочатку нові')}
          >
            спочатку нові
          </li>
          <li
            className={filterSort.includes('спочатку дорожчі') ? 'active' : ''}
            style={
              filterSort.includes('спочатку дорожчі')
                ? {
                    border: `1px solid ${
                      ['#f4b710', '#a0d1c7', '#f08bb8'][
                        Math.floor(Math.random() * 3)
                      ]
                    }`,
                  }
                : {}
            }
            onClick={() => handleFilterSort('спочатку дорожчі')}
          >
            спочатку дорожчі
          </li>
          <li
            className={filterSort.includes('спочатку дешевші') ? 'active' : ''}
            style={
              filterSort.includes('спочатку дешевші')
                ? {
                    border: `1px solid ${
                      ['#f4b710', '#a0d1c7', '#f08bb8'][
                        Math.floor(Math.random() * 3)
                      ]
                    }`,
                  }
                : {}
            }
            onClick={() => handleFilterSort('спочатку дешевші')}
          >
            спочатку дешевші
          </li>
        </ul>
      </div>
      <div className="Filters__buttons">
        <button
          className="useFilters"
          onClick={() => {
            fetchProductsData('filter');
            handleFilterToggle();
          }}
        >
          Застосувати
        </button>
        <button
          className="useFilters"
          onClick={() => {
            resetFilters();
          }}
        >
          Скасувати
        </button>
      </div>
    </div>
  );
}

export default Filters;

import { FiHeart, FiSearch, FiX } from 'react-icons/fi';
import Nav from './Nav';
import './Header.scss';
import logo from './img/nfe_1.2_logo_color.png';
import instagram from './img/instagram.svg';
import tiktok from './img/TikTok.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';

function Header({
  searchQuery,
  handleSearch,
  handleSearchSubmit,
  handleSearchClean,
  wishList,
  filterWish,
  handleFilterWish,
  filterActive,
  handleFilterToggle,
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = (event) => {
    navigate('/');
    event.preventDefault();
    handleSearchSubmit();
  };

  return (
    <header
      className={`${
        location.pathname !== '/' ? 'Header Header__custom' : 'Header'
      }`}
    >
      <Nav
        className="Header__nav"
        searchQuery={searchQuery}
        handleSearch={handleSearch}
        handleSearchSubmit={handleSearchSubmit}
        handleSearchClean={handleSearchClean}
        handleSubmit={handleSubmit}
      />
      {location.pathname === '/' && (
        <button
          className={`Header__filters ${filterActive ? 'active' : ''}`}
          onClick={() => handleFilterToggle()}
          style={
            filterActive
              ? {
                  borderColor: `${
                    ['#f4b710', '#a0d1c7', '#f08bb8'][
                      Math.floor(Math.random() * 3)
                    ]
                  }`,
                  animationName: `${
                    ['pulsate_yellow', 'pulsate_mint', 'pulsate_pink'][
                      Math.floor(Math.random() * 3)
                    ]
                  }`,
                }
              : {}
          }
        >
          {filterActive && 'Є '}фільтри
        </button>
      )}
      {filterWish.length || searchQuery ? (
        <Link
          className="Header__logo"
          to="/"
          onClick={() => {
            handleFilterWish(wishList);
            handleSearchClean();
            navigate('/');
          }}
        >
          <img src={logo} alt="" />
        </Link>
      ) : (
        <Link className="Header__logo" to="/">
          <img src={logo} alt="" />
        </Link>
      )}

      <div className="Header__icons">
        {/* <FiSearch className="buttonSearch" /> */}
        <div className="Header__icons__search">
          <form onSubmit={handleSubmit}>
            <input
              type="search"
              placeholder="ШУКАТИ:"
              onChange={handleSearch}
              value={searchQuery ? searchQuery : ''}
              onSubmit={handleSearchSubmit}
            />
          </form>
          {searchQuery ? (
            <FiX
              className="buttonSearchClean"
              onClick={() => handleSearchClean()}
            />
          ) : (
            <FiSearch className="buttonSearch" />
          )}
        </div>
        <>
          {/* <FiSearch className="buttonSearch" /> */}
          {wishList.length > 0 ? (
            <FiHeart
              className={filterWish.length ? 'buttonLike active' : 'buttonLike'}
              style={{
                stroke: `${
                  ['#f4b710', '#a0d1c7', '#f08bb8'][
                    Math.floor(Math.random() * 3)
                  ]
                }`,
                animationName: `${
                  ['pulsate_yellow', 'pulsate_mint', 'pulsate_pink'][
                    Math.floor(Math.random() * 3)
                  ]
                }`,
              }}
              onClick={() => {
                handleFilterWish(wishList);
                navigate('/');
              }}
            />
          ) : (
            ''
          )}
        </>
        <a
          className="buttonInstagram"
          href="https://www.instagram.com/notforeveryone.ua/"
          target="_blank"
        >
          <img src={instagram} alt="Instagram" />
        </a>
        <a
          className="buttonTiktok"
          href="https://www.tiktok.com/@notforeveryone.ua"
          target="_blank"
        >
          <img src={tiktok} alt="TikTok" />
        </a>
      </div>
      <div className="mobileLike">
        {/* <FiSearch className="buttonSearch" /> */}
        {wishList.length > 0 ? (
          <FiHeart
            className={filterWish.length ? 'buttonLike active' : 'buttonLike'}
            style={{
              stroke: `${
                ['#f4b710', '#a0d1c7', '#f08bb8'][Math.floor(Math.random() * 3)]
              }`,
              animationName: `${
                ['pulsate_yellow', 'pulsate_mint', 'pulsate_pink'][
                  Math.floor(Math.random() * 3)
                ]
              }`,
            }}
            onClick={() => {
              handleFilterWish(wishList);
              navigate('/');
            }}
          />
        ) : (
          ''
        )}
      </div>
    </header>
  );
}

export default Header;
